<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Flujo de Ventas - Contrato</h4>
            <div class="small text-muted">Configuración del formato estandar del contrato..</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Contrato">
              <i class="fa fa-plus"></i> Agregar
            </b-button>
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    v-if="table.items.length">

                <template v-slot:cell(id)="data">
                  <b>{{ data.item.id }}</b>
                </template>              

                <template v-slot:cell(name)="data">
                  {{ data.item.name }}
                </template>                  

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-button :disabled="crud.form.editor.type=='html'" 
                      @click="crud.form.editor.type='html'"
                      size="sm"
                      variant="warning"
                      class="pull-left mb-2">
              HTML
            </b-button>               
          </b-col>          
          <b-col lg="6">
            <b-button :disabled="crud.form.editor.type=='preview'" 
                      @click="crud.form.editor.type='preview'"
                      size="sm"
                      variant="primary"
                      class="pull-right mb-2">
              VISTA PREVIA
            </b-button>    
          </b-col>   
          <b-col lg="12" v-show="crud.form.editor.type=='html'">                      
            <prism-editor v-model="crud.form.content" language="html" class="crud-contract-code-editor"></prism-editor>            
          </b-col>
          <b-col lg="12" v-show="crud.form.editor.type=='preview'">
            <div class="crud-contract-preview-contract">
              <div v-html="crud.form.content" class="crud-contract-preview-contract-html"></div>
            </div>
          </b-col>          
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default {
    components: {
      PrismEditor
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.FLUJO_VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudContract',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true},
            {key: 'name', label: 'Nombre'},            
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        crud: {
          form: {
            id: 0,
            name: '',
            content: '',
            editor: {
              type: "html"
            }
          },          
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      load() {
        var result = serviceAPI.obtenerContrato()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = '' 
        this.crud.form.content = '' 
        this.modal.form.title = "Nuevo Contrato"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name      
        this.crud.form.content = item.content      
        this.modal.form.title = "Editar Contrato"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el contrato (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Contrato',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarContrato(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarContrato(this.crud.form);
        } else {
          var result = serviceAPI.agregarContrato(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
    }    
  }
</script>
<style>
 .crud-contract-preview-contract {
    background: #e5e5e5;    
    padding: 15px;
    height: 450px;        
  }
  .crud-contract-preview-contract .crud-contract-preview-contract-html {
    background: #fff;
    height: 450px;     
    padding: 15px;   
    overflow: overlay;
  }  
  .crud-contract-code-editor {
    height: 450px;
  }
  .crud-contract-code-editor pre {
    height: 450px;
  }  
</style>